<template>
    <div class="invoice">
        <div class="page-header">
            <el-row>
                <el-col :span="24">
                    <div class="header-desc">
                        <router-link to><el-button icon="el-icon-arrow-left" round @click="$router.back(-1)">{{ $t('data.public.back') }}</el-button></router-link>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="invoice-wrap" id="print-invoice" v-if="invoiceData">
            <div class="invoice-left">
            </div>
            <div class="invoice-content">
                <div class="invoice-logo">
                    <img src="/image/invoice-logo.png?01" alt="">
                </div>
                <div class="invoice-info">
                    <h2 v-html="$t('data.invoice.invoice')"></h2>
                    <p>{{ $t('data.invoice.orderDate') }}: {{invoiceData.created_at}}</p>
                    <p>{{ $t('data.invoice.invoiceNumber') }}: {{invoiceData.no}}</p>
                    <p>{{ $t('data.invoice.paymentMethod') }}: Stripe/Master Card</p>
                </div>
                <div class="school-info">
                    <h2 style="font-size:16px">{{invoiceData.organization.name}}</h2>
                    <p>
                        {{ $t('data.invoice.name') }}: {{invoiceData.organization.one_contact_person}}<br>
                        {{ $t('data.invoice.organisationAddress') }}:<br>
                        {{invoiceData.organization.address}}
                        <br>
                        {{ $t('data.invoice.email') }}: {{invoiceData.organization.email}}
                    </p>
                </div>

                <div class="invoice-table">
                    <el-table
                        :header-cell-style="{background:'#007fb9', color:'#fff', webkitPrintColorAdjust: 'exact'}"
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                            prop="id"
                            label="ID">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            :label="$t('data.invoice.item')">
                        </el-table-column>
                        <el-table-column
                            prop="price"
                            :label="$t('data.invoice.total')">
                        </el-table-column>
                    </el-table>
                    <el-row :gutter="20" class="mt-20">
                        <el-col :span="20">
                            <div class="count-name">
                                <p>{{ $t('data.invoice.subtotal') }}:</p>
                                <p>{{ $t('data.invoice.tax') }}({{invoiceData.tax}}%):</p>
                                <p><strong>{{ $t('data.invoice.grandTotal') }}:</strong></p>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="count-money">
                                <p>${{invoiceData.original}}</p>
                                <p>${{invoiceData.tax_price}}</p>
                                <p>${{invoiceData.price}}</p>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div class="invoice-footer">
                    <div class="invoice-footer-box">
                        <div class="questions">
                            {{ $t('data.invoice.questions') }}?<br>
                            {{ $t('data.invoice.emailUsAt') }}: cs@cert.app
                        </div>
                        <div class="thanks">
                            THANK<br>YOU!
                        </div>
                    </div>
                    <p class="invoice-footer-domain">www.ecert.app</p>
                </div>
            </div>
        </div>

        <el-row :gutter="20">
            <el-col :span="24" align="center" class="mt-20">
                <el-button v-print="'#print-invoice'">Print</el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import { pricingDetail } from '../../api/index'
export default {
    name: 'invoice',
    data(){
        return {
            tableData: [],
            invoiceData: null
        }
    },
    computed:{
        totalMoney: function(){
            let total = 0;
            this.tableData.forEach(item => {
                total = total + item.price
            });
            return total;
        }  
    },
    mounted(){
        this.initPageData();
    },
    methods:{
        initPageData(){
            let apiData = {
              id: this.$route.params.id
            }
            pricingDetail({apiData}).then( res => {
                this.invoiceData = res.data;
                this.tableData = [];
                this.tableData.push(res.data.package);
                this.$nextTick(function(){
                    let domColgroup = window.document.getElementsByTagName('colgroup')
                    domColgroup[0].remove();
                })
                
            })
        },
    }
    
}
</script>

<style>
@page {  
  size: A4;
  margin-top: 0mm;
  margin-bottom: 0mm;
  margin-left: 0mm; 
  margin-right: 0mm
}

@media print {

  #print-invoice .invoice-left{
    background: -webkit-linear-gradient(#009dd0,#003c7f);
    background: -o-linear-gradient(#009dd0,#003c7f);
    background: -moz-linear-gradient(#009dd0,#003c7f);
    background: -mos-linear-gradient(#009dd0,#003c7f);
    background: linear-gradient(#009dd0,#003c7f);
  }
    .el-table__header colgroup{
      display: none !important;
  }

}

</style>

<style scoped>

.invoice{
    display: block;
    width: 100%;
}
.invoice-wrap{
    width: 794px;
    height: 1123px;
    background-color: #fff;
    margin: 0 auto;
    display: flex;
}
.invoice-left{
    width: 100px;
    height: 100%;
    background: -webkit-linear-gradient(#009dd0,#003c7f);
    background: -o-linear-gradient(#009dd0,#003c7f);
    background: -moz-linear-gradient(#009dd0,#003c7f);
    background: -mos-linear-gradient(#009dd0,#003c7f);
    background: linear-gradient(#009dd0,#003c7f);
    -webkit-print-color-adjust: exact;
}
.invoice-content{
    width: 694px;
    padding: 15px;
    box-sizing:border-box;
    position: relative;
}
.invoice-logo img{
    width: 200px;
    height: auto;
    display:block
}
.invoice-info{
    text-align: right;
    margin-bottom: 30px;
}
.invoice-info h2{
    margin-bottom: 20px;
    font-size: 32px;
}
.invoice-info p{
    font-size: 16px;
    line-height: 26px;
    color: #333;
}
.school-info{
    margin-bottom: 30px;
}
.school-info h2{
    margin-bottom: 20px;
    font-size: 32px;
}
.school-info p{
    font-size: 16px;
    line-height: 26px;
    color: #333;
}
.invoice-table{
    margin-bottom: 60px;
}
.count-name p, .count-money p{
    line-height: 32px;
    text-align: right;

}
.invoice-footer{
    position: absolute;
    right: 15px;
    left: 15px;
    bottom: 0px;
}
.invoice-footer-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid #00599a;
    padding-bottom: 20px;
}
.invoice-footer-box .questions{
    font-size: 16px;
    line-height: 26px;
    color: #333;
}
.invoice-footer-box .thanks{
    color: #00599a;
    font-weight: 600;
    font-size: 45px;
    line-height: 45px;
    text-align: right;
}
.invoice-footer-domain{
    padding: 15px 0px;
    font-size: 16px;
    text-align: center;
    color: #333;
}
</style>